import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AiOutlineHome, AiOutlineShop } from 'react-icons/ai';
import { RiSecurePaymentLine } from 'react-icons/ri';
import ShopList from './components/ShopList';
import ApiTokenForm from './components/ApiTokenForm';
import LocationUpdateForm from './components/LocationUpdateForm';
import CustomerList from './components/CustomerList';
import DeclinedServiceList from './components/declinedServicesList';
import CustomerDetail from './components/CustomerDetail';
import axios from 'axios';



function App() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  console.log('Backend URL:', backendUrl);

  const [user, setUser] = useState(null);
  const [avatar, setAvatar] = useState(null);
  

  // Fetch authenticated user data from the backend
  useEffect(() => {
    axios.get(`${backendUrl}/auth/user`, { withCredentials: true })
      .then((response) => {
        setUser(response.data.user);  // Store the user info if authenticated
        setAvatar(response.data.user.photos[0]?.value);
      })
      .catch(() => {
        setUser(null);  // No user, not authenticated
      });
  }, [backendUrl]);

  return (
    <Router>
      <div className="min-h-screen flex ">
        {/* Sidebar */}
        <aside className="w-full max-w-64 min-w-64 bg-white shadow-lg p-6 space-y-4">
          <Link to="/" className="text-center text-primary-base text-2xl font-bold mb-8 ">
            <img src="https://cdn.prod.website-files.com/6659dfba7a583d4883d4186a/666b3f4a01058ef52f958db3_Campanellas%20Logo%20v6%20Black.svg" alt="logo" />
          </Link>

          {/* Display Login/Logout based on user's authentication state */}
          <div className="text-center mb-8">
            {user ? (
              <div className="flex flex-col items-center space-y-4">
                <img src={avatar} alt="Avatar" className="w-24 h-24 rounded-full" />
                <p className="text-lg font-semibold">Hello, {user.displayName}</p>
                <button
                  className="p-2 bg-red-500 text-white rounded"
                  onClick={() => (window.location.href = backendUrl+'/auth/logout')}
                >
                  Logout
                </button>
              </div>
            ) : (
              <button
                className="p-2 bg-blue-500 text-white rounded"
                onClick={() => (window.location.href = backendUrl+'/auth/google')}
              >
                Login with Google
              </button>
            )}
          </div>


          <nav className="space-y-2 t-6">
            <Link to="/" className="flex items-center space-x-3 p-2 rounded hover:bg-blue-100 transition-colors duration-200">
              <AiOutlineHome className="text-2xl text-primary-base" />
              <span className="text-gray-800 text-lg">Home</span>
            </Link>
            {user && (
              <>
                {/* <Link to="/update-token" className="flex items-center space-x-3 p-2 rounded hover:bg-blue-100 transition-colors duration-200">
                  <RiSecurePaymentLine className="text-2xl text-primary-base" />
                  <span className="text-gray-800 text-lg">Update API Token</span>
                </Link> */}
                <Link to="/shops" className="flex items-center space-x-3 p-2 rounded hover:bg-blue-100 transition-colors duration-200">
                  <AiOutlineShop className="text-2xl text-primary-base" />
                  <span className="text-gray-800 text-lg">All Customers Shop List</span>
                </Link>
                <Link to="/declined-orders" className="flex items-center space-x-3 p-2 rounded hover:bg-blue-100 transition-colors duration-200">
                  <AiOutlineShop className="text-2xl text-primary-base" />
                  <span className="text-gray-800 text-lg">Declined Jobs Shop List</span>
                </Link>
                {/* <Link to="/update-location" className="flex items-center space-x-3 p-2 rounded hover:bg-blue-100 transition-colors duration-200">
                  <RiSecurePaymentLine className="text-2xl text-primary-base" />
                  <span className="text-gray-800 text-lg">Update Location</span>
                </Link> */}
              </>
            )}
          </nav>
        </aside>

        {/* Main Content Area */}
        <div className="flex-1 p-8">
          <Routes>
            {/* <Route path="/update-token" element={<ApiTokenForm />} /> */}
            {/* <Route path="/update-location" element={<LocationUpdateForm />} /> */}
            <Route path="/shops" element={<ShopList />} />
            <Route path="/declined-orders" element={<DeclinedServiceList />} />
            <Route path="/shops/:shopId/customers" element={<CustomerList />} />
            <Route path="/customers/:customerId" element={<CustomerDetail />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
