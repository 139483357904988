import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import CustomerDetailsSkeleton from './skeletons/CustomerDetailsSkeleton';
import toast, { Toaster } from 'react-hot-toast';
import Spinner from './skeletons/Spinner';

const CustomerDetail = () => {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [UpdateLoading, setUpdateLoading] = useState(false);

  console.log("update datae", customer)

  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [updateData, setUpdateData] = useState({
    firstName: '',
    lastName: '',
    email: [''],
    phones: [{ number: '', type: 'Mobile', primary: true }], // Initialize phones as an array of objects
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: ''
    },
    notes: '',
    contactFirstName: '',
    contactLastName: '',
    okForMarketing: false,
    customerType: '' // Assuming customerType can be changed
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await axios.get(`/api/customers/${customerId}`);

        // Convert email to array if it's a string
        const emailData = Array.isArray(response.data.email)
          ? response.data.email
          : response.data.email 
          ? [response.data.email]  // Convert single email string to array
          : [''];

        const phoneData = response.data.phone && Array.isArray(response.data.phone)
          ? response.data.phone
          : [{ number: '', type: 'Mobile', primary: true }];

        setCustomer(response.data);
        setUpdateData({
          firstName: response.data.firstName || '',
          lastName: response.data.lastName || '',
          email: emailData,
          phones: phoneData,
          address: {
            address1: response.data.address?.address1 || '',
            address2: response.data.address?.address2 || '',
            city: response.data.address?.city || '',
            state: response.data.address?.state || '',
            zip: response.data.address?.zip || ''
          },
          notes: response.data.notes || '',
          contactFirstName: response.data.contactFirstName || '',
          contactLastName: response.data.contactLastName || '',
          okForMarketing: response.data.okForMarketing || false,
          customerType: response.data.customerType?.name || '' // Assuming type name is editable
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching customer:', error);
        setError(error.response?.data?.error || 'Failed to fetch customer');
        setLoading(false);
      }
    };

    fetchCustomer();
  }, [customerId]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if the field is part of the address object
    if (name in updateData.address) {
      setUpdateData((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          [name]: value
        }
      }));
    } else {
      setUpdateData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Handle change for dynamic email inputs
  const handleEmailChange = (index, value) => {
    const updatedEmails = [...updateData.email];
    updatedEmails[index] = value;
    setUpdateData((prevData) => ({
      ...prevData,
      email: updatedEmails,
    }));
  };

  // Handle change for dynamic phone inputs
  const handlePhoneChange = (index, value, field) => {
    const updatedPhones = [...updateData.phones];
    updatedPhones[index] = { ...updatedPhones[index], [field]: value };
    setUpdateData((prevData) => ({
      ...prevData,
      phones: updatedPhones,
    }));
  };

  // Add a new email input field
  const addEmailField = () => {
    setUpdateData((prevData) => ({
      ...prevData,
      email: [...prevData.email, '']
    }));
  };

  // Remove an email input field
  const removeEmailField = (index) => {
    const updatedEmails = updateData.email.filter((_, i) => i !== index);
    setUpdateData((prevData) => ({
      ...prevData,
      email: updatedEmails
    }));
  };

  // Add a new phone input field
  const addPhoneField = () => {
    setUpdateData((prevData) => ({
      ...prevData,
      phones: [...prevData.phones, { number: '', type: 'Mobile', primary: false }]
    }));
  };

  // Remove a phone input field
  const removePhoneField = (index) => {
    const updatedPhones = updateData.phones.filter((_, i) => i !== index);
    setUpdateData((prevData) => ({
      ...prevData,
      phones: updatedPhones
    }));
  };

  const handleUpdate = async () => {
    setUpdateLoading(true); // Set loading to true when request starts
    try {
      const response = await axios.patch(`/api/customers/${customerId}`, updateData);

      // Update the customer state with the latest data from the response
      setCustomer(response.data);

      window.location.reload(); // Refresh the page after a successful update
      setIsEditing(false);
      toast.success('Customer updated successfully');
      setUpdateLoading(false); // Set loading to false after request is done
      
    } catch (error) {
      console.error('Error updating customer:', error);
      setError(error.response?.data?.error || 'Failed to update customer');
      toast.error(error.response?.data?.error || 'Failed to update customer');
      setUpdateLoading(false); // Set loading to false if an error occurs
    }
  };

  if (loading) return <CustomerDetailsSkeleton />;
  // if (error) return <p>{error}</p>;

  return (
    <div className="container mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-6">Customer Details</h2>
      {isEditing ? (
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700">First Name:</label>
              <input
                type="text"
                name="firstName"
                value={updateData.firstName}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700">Last Name:</label>
              <input
                type="text"
                name="lastName"
                value={updateData.lastName}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-gray-700">Emails:</label>
            {updateData.email.map((email, index) => (
              <div key={index} className="flex items-center space-x-2 mb-2">
                <input
                  type="email"
                  name={`email-${index}`}
                  value={email}
                  onChange={(e) => handleEmailChange(index, e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {updateData.email.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeEmailField(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={addEmailField}
              className="text-blue-500 hover:text-blue-700"
            >
              Add Email
            </button>
          </div>

          <div>
            <label className="block text-gray-700">Phones:</label>
            {updateData.phones.map((phone, index) => (
              <div key={index} className="grid grid-cols-4 gap-2 items-center mb-2">
                <input
                  type="text"
                  name={`phone-number-${index}`}
                  placeholder="Number"
                  value={phone.number}
                  onChange={(e) => handlePhoneChange(index, e.target.value, 'number')}
                  className="col-span-2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <select
                  name={`phone-type-${index}`}
                  value={phone.type}
                  onChange={(e) => handlePhoneChange(index, e.target.value, 'type')}
                  className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="Mobile">Mobile</option>
                  <option value="Home">Home</option>
                  <option value="Work">Work</option>
                </select>
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name={`phone-primary-${index}`}
                    checked={phone.primary}
                    onChange={(e) => handlePhoneChange(index, e.target.checked, 'primary')}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />{' '}
                  <span>Primary</span>
                  {updateData.phones.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removePhoneField(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={addPhoneField}
              className="text-blue-500 hover:text-blue-700"
            >
              Add Phone
            </button>
          </div>

          <div>
            <label className="block text-gray-700">Address:</label>
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                name="address1"
                placeholder="Address Line 1"
                value={updateData.address.address1}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                name="address2"
                placeholder="Address Line 2"
                value={updateData.address.address2}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="grid grid-cols-3 gap-4 mt-2">
              <input
                type="text"
                name="city"
                placeholder="City"
                value={updateData.address.city}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                name="state"
                placeholder="State"
                value={updateData.address.state}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                name="zip"
                placeholder="Zip Code"
                value={updateData.address.zip}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-gray-700">Notes:</label>
            <textarea
              name="notes"
              value={updateData.notes}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700">Contact First Name:</label>
              <input
                type="text"
                name="contactFirstName"
                value={updateData.contactFirstName}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-700">Contact Last Name:</label>
              <input
                type="text"
                name="contactLastName"
                value={updateData.contactLastName}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-gray-700">Customer Type:</label>
            <input
              type="text"
              name="customerType"
              value={updateData.customerType}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              name="okForMarketing"
              checked={updateData.okForMarketing}
              onChange={(e) => handleChange({ target: { name: 'okForMarketing', value: e.target.checked } })}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <label className="text-gray-700">Ok for Marketing</label>
          </div>

          <div className="flex justify-end space-x-4 mt-4">
            <button
              onClick={handleUpdate}
              className="bg-primary-base text-white px-6 py-2 rounded-lg "
            >
            {UpdateLoading ?  <Spinner /> : "Save"}
            </button>
            <button
              onClick={handleEditToggle}
              className="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="bg-gray-50 p-6 rounded-lg shadow-inner space-y-4">
  <div className="flex items-start">
    <p className="text-lg font-semibold w-48">Name:</p>
    <p className="text-lg">{customer.firstName} {customer.lastName}</p>
  </div>

  <div className="flex items-start">
    <p className="text-lg font-semibold w-48">Emails:</p>
    <ul className="list-none space-y-1">
      {/* {Array.isArray(customer.email) && customer.email.length > 0 ? ( */}
        {/* customer.email.map((email, index) => ( */}
          <li  className="text-gray-700">
           {customer?.email ?  <a href={`mailto:${customer?.email}`} className="text-blue-500 hover:underline">
              {customer?.email }
            </a>: "No email availible"}
          </li>
        {/* ))
      ) : (
        <li className="text-gray-700">No email available</li>
      )} */}
    </ul>
  </div>

  <div className="flex items-start">
    <p className="text-lg font-semibold w-48">Phones:</p>
    <ul className="list-none space-y-1">
      {customer.phone && Array.isArray(customer.phone) && customer.phone.length > 0 ? (
        customer.phone.map((phone, index) => (
          <li key={index} className="">
            {phone.number} ({phone.type}) {phone.primary && "(Primary)"}
          </li>
        ))
      ) : (
        <li className="text-gray-700">No phone available</li>
      )}
    </ul>
  </div>

  <div className="flex items-start">
    <p className="text-lg font-semibold w-48">Address:</p>
    <p className="text-lg">{customer.address?.fullAddress || 'No address available'}</p>
  </div>

  <div className="flex items-start">
    <p className="text-lg font-semibold w-48">Notes:</p>
    <p className="text-lg">{customer.notes || 'No notes available'}</p>
  </div>

  <div className="flex items-start">
    <p className="text-lg font-semibold w-48">Contact Name:</p>
    <p className="text-lg">{customer.contactFirstName} {customer.contactLastName}</p>
  </div>

  <div className="flex items-start">
    <p className="text-lg font-semibold w-48">Customer Type:</p>
    <p className="text-lg">{customer.customerType?.name || 'N/A'}</p>
  </div>

  <div className="flex items-start">
    <p className="text-lg font-semibold w-48">Marketing Opt-In:</p>
    <p className="text-lg">{customer.okForMarketing ? 'Yes' : 'No'}</p>
  </div>

  <div className="flex space-x-4 justify-end mt-6">
    <button
      onClick={handleEditToggle}
      className="bg-primary-base text-white px-6 py-2 rounded-lg "
    >
      Edit
    </button>
    <button
      onClick={() => navigate(-1)}
      className="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600"
    >
      Back
    </button>
  </div>
</div>

      
      )}
    </div>
  );
};

export default CustomerDetail;
