import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const ApiTokenForm = () => {
  const [tokens, setTokens] = useState([]);
  const [serviceName, setServiceName] = useState('ghl'); // Default to 'ghl'
  const [apiToken, setApiToken] = useState('');
  const [message, setMessage] = useState('');

  // Fetch all tokens when the component mounts
  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const response = await axios.get('/api/token', {
          headers: {
            'x-api-key': 'your-secret-key', // Replace with your actual API key
          },
        });
        console.log('Tokens: ===================== ', response);
        setTokens(response.data); // Set the fetched tokens in the state
      } catch (error) {
        console.error('Error fetching tokens:', error);
        setMessage(error.response?.data?.error || 'Failed to fetch tokens');
      }
    };
    fetchTokens();
  }, []);

  // Handle form submission for adding or updating a token
  const handleSubmit = async (e) => {
    // console.log("evvv", e)
    e.preventDefault();
    try {
      const response = await axios.post(
        '/api/token',
        {
          service_name: serviceName,
          api_token: apiToken,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'your-secret-key', // Replace with your actual API key
          },
        }
      );

      // Update the tokens state with the new or updated token
      setTokens((prevTokens) => {
        const updatedTokens = [...prevTokens];
        const index = updatedTokens.findIndex(token => token.service_name === serviceName);
        if (index >= 0) {
          updatedTokens[index].api_token = apiToken; // Update existing token
        } else {
          updatedTokens.push(response.data.token); // Add new token
        }
        return updatedTokens;
      });

      setMessage(response.data.message || 'Token updated successfully');
      toast.success("Token updated successfully")
      setServiceName('ghl'); // Reset to default after submit
      setApiToken('');
    } catch (error) {
      console.error('Error updating token:', error);
      setMessage(error.response?.data?.error || 'Failed to update token');
    }
  };

  // Handle change for existing token in the list
  const handleTokenChange = (index, value) => {
    const updatedTokens = [...tokens];
    updatedTokens[index].api_token = value;
    setTokens(updatedTokens);
  };

  return (
   <div className="container mx-auto p-8 bg-white shadow-lg rounded-lg">
  <h1 className="text-3xl font-bold text-primary-base mb-6">Manage API Tokens</h1>
  
  <form onSubmit={handleSubmit} className="space-y-4 mb-6">
    <div>
      <label className="block text-lg font-medium text-gray-700">Service Name:</label>
      <select
        value={serviceName}
        onChange={(e) => setServiceName(e.target.value)}
        required
        className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      >
        <option value="ghl">GHL</option>
        <option value="tekmetric">Tekmetric</option>
        <option value="locationId">Location ID</option>
      </select>
    </div>
    <div>
      <label className="block text-lg font-medium text-gray-700">API Token:</label>
      <input
        type="text"
        value={apiToken}
        onChange={(e) => setApiToken(e.target.value)}
        required
        className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      />
    </div>
   <div className='flex justify-end'> <button
      type="submit"
      className="bg-primary-base text-white px-4 py-2 rounded-lg shadow-md"
    >
      Add/Update Token
    </button></div>
  </form>

  {message && <p className="text-green-600 mb-6">{message}</p>}

  <h2 className="text-2xl font-semibold text-primary-base mb-4">Existing Tokens</h2>
  <ul className="space-y-4">
    {tokens.map((token, index) => (
      <li
        key={token.service_name}
        className="bg-gray-100 p-4 rounded-lg shadow-sm flex flex-col md:flex-row items-start md:items-center md:justify-between space-y-2 md:space-y-0 md:space-x-4"
      >
        <div className="flex-1">
          <p className="text-lg">
            <strong className="font-semibold">Service Name:</strong> {token.service_name}
          </p>
          <label className="block text-gray-600 mt-2">API Token:</label>
          <div className='flex items-center space-x-4'>
          <input
            type="text"
            value={token.api_token}
            onChange={(e) => handleTokenChange(index, e.target.value)}
            className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <button
          onClick={() =>
            handleSubmit({
              service_name: token.service_name,
              api_token: token.api_token,
            })
          }
          className="bg-primary-base text-white px-4 py-2 rounded-lg  shadow-md mt-2 md:mt-0"
        >
          Update
        </button>
          </div>
         
        </div>
        
      </li>
    ))}
  </ul>
</div>

  );
};

export default ApiTokenForm;
