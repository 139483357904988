import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import SkeletonTable from './skeletons/SkeletonTable';

const CustomerList = () => {
  const { shopId } = useParams();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get(`/api/customers`, {
          params: { shopId }
        });
        setCustomers(response.data.content);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching customers:', error);
        setError(error.response?.data?.error || 'Failed to fetch customers');
        setLoading(false);
      }
    };

    if (shopId) {
      fetchCustomers();
    }
  }, [shopId]);

  if (loading) return <SkeletonTable />;
  if (error) return <p>{error}</p>;

  return (
    <div className="container mx-auto bg-white shadow-md rounded my-6 p-4 sm:p-6">
    <h2 className="text-xl text-primary-base font-bold mb-4">
      Customers for Shop ID: {shopId}
    </h2>
    {/* Add max-w-full and overflow-x-auto to contain the table */}
    <div className="max-w-full overflow-x-auto">
      <table className="min-w-full w-full table-auto">
        <thead>
          <tr className="bg-gray-200 text-gray-600 uppercase text-xs sm:text-sm leading-normal">
            <th className="py-2 sm:py-3 px-2 sm:px-6 text-left">Customer Details</th>
            <th className="py-2 sm:py-3 px-2 sm:px-6 text-left">Contact Info</th>
            <th className="py-2 sm:py-3 px-2 sm:px-6 text-left">Address</th>
            <th className="py-2 sm:py-3 px-2 sm:px-6 text-center">Action</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-xs sm:text-sm font-light">
          {customers.map((customer) => (
            <tr
              key={customer.id}
              className="border-b border-gray-200 hover:bg-gray-100"
            >
              {/* Customer Details */}
              <td className="py-2 sm:py-3 px-2 sm:px-6 text-left whitespace-normal">
                <h2 className="font-semibold">{customer.firstName} {customer.lastName}</h2>
                {customer.nickname && (
                  <p className="text-sm text-gray-600">
                    <strong>Nickname:</strong> {customer.nickname}
                  </p>
                )}
              </td>
              {/* Contact Info */}
              <td className="py-2 sm:py-3 px-2 sm:px-6 text-left">
                <p>{customer.phone[0]?.number || 'N/A'}</p>
                <p>
                  <a
                    href={`mailto:${customer.email}`}
                    className="text-blue-500 hover:underline"
                  >
                    {customer.email}
                  </a>
                </p>
                {customer.website && (
                  <p>
                    <a
                      href={`https://${customer.website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      {customer.website}
                    </a>
                  </p>
                )}
              </td>
              {/* Address Info */}
              <td className="py-2 sm:py-3 px-2 sm:px-6 text-left">
                <p>{customer.address?.fullAddress || 'N/A'}</p>
              </td>
              {/* Actions */}
              <td className="py-2 sm:py-3 px-2 sm:px-6 text-center">
                <Link to={`/customers/${customer.id}`}>
                  <button className="bg-primary-base text-white py-1 px-3 rounded hover:bg-primary-dark">
                    View & Edit
                  </button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  );
};

export default CustomerList;
