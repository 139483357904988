import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const DeclinedServiceItem = ({ shop, setTotalContacts, setSavedContacts, setStatus, setError, setShowDetails }) => {
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [polling, setPolling] = useState(false);
  const [progressData, setProgressData] = useState([]); // State to hold the progress data

  // Start the bulk import process
  const handleBulkImport = async () => {
    setLoading(true);
    setCompleted(false);
    setError('');
    setShowDetails(true);

    const data = JSON.stringify({ shopId: shop.id });
    const config = {
      method: 'post',
      url: '/api/bulk-import/declined/customers',
      headers: { 'Content-Type': 'application/json' },
      data,
    };

    try {
      await axios(config);
      setPolling(true); // Begin polling when import is triggered
    } catch (error) {
      console.error(error);
      setError('Error triggering bulk import.');
      setLoading(false);
    }
  };

  // Polling logic using useEffect
  useEffect(() => {
    let intervalId;

    if (polling) {
      intervalId = setInterval(async () => {
        try {
          const progressResponse = await axios.get(`/api/import-progress/declined/${shop.id}`);
          const { totalContacts, savedContacts, status } = progressResponse.data;

          if (totalContacts !== null && totalContacts !== undefined) {
            setTotalContacts(totalContacts);
          }
          if (savedContacts !== null && savedContacts !== undefined) {
            setSavedContacts(savedContacts);
          }

          setStatus(status);

          if (status === 'completed' || status === 'failed') {
            clearInterval(intervalId);
            setLoading(false);
            setCompleted(status === 'completed');
            setPolling(false);
          }
        } catch (err) {
          console.error('Failed to get progress', err);
          setError('Failed to get progress');
          clearInterval(intervalId);
          setLoading(false);
          setPolling(false);
        }
      }, 3000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [polling, shop.id]);

  // Fetch the progress data for all shops
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/import-progress-database'); // Make API call to fetch progress
        if (response.status === 200) {
          setProgressData(response.data);
        }
      } catch (error) {
        console.error('Error fetching progress data:', error);
      }
    };

    fetchData();
  }, []);

  // Find the progress for this specific shop
  const shopProgress = progressData.find((progress) => progress.shopId === shop.id.toString());
  return (
    <tr className="border-b border-gray-200 hover:bg-gray-100">
      <td className="py-3 px-2 text-left">
        <h2 className="font-semibold w-full max-w-52 text-sm sm:text-base">{shop.name} {shop.id}</h2>
      </td>
      <td className="py-3 px-2 text-left">
        <p className="text-xs sm:text-sm">{shop.phone}</p>
        <p className="text-xs sm:text-sm">
          <a href={`mailto:${shop.email}`} className="text-blue-500 hover:underline">
            {shop.email}
          </a>
        </p>
        <p className="text-xs sm:text-sm truncate">
          <a
            href={`https://${shop.website}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            {shop.website}
          </a>
        </p>
      </td>
      <td className="py-3 px-2 max-w-44 text-left text-xs sm:text-sm md:text-base">
        <p className="whitespace-pre-wrap break-words">{shop.address?.fullAddress}</p>
      </td>
      <td className="py-3 px-2 text-center text-xs sm:text-sm">
        <p>{shop.roCustomLabelEnabled ? 'Yes' : 'No'}</p>
      </td>
      <td className="py-3 px-2 text-center">
        {/* <Link to={`/shops/${shop.id}/customers`}>
          <button className="bg-primary-base text-white py-1 px-2 sm:px-3 rounded text-xs sm:text-sm">
            View Customers
          </button>
        </Link> */}
        <button
          onClick={handleBulkImport}
          className="bg-primary-base text-white py-1 px-2 sm:px-3 rounded text-xs sm:text-sm ml-2"
          disabled={loading}  // Disable the button when loading is true
        >
          {loading ? 'Processing...' : 'Bulk Import'}
        </button>

        {/* Display progress details for the current shop */}
        {shopProgress && (
  <div className='flex flex-col w-full max-w-[240px] mt-3'>
    <p className="text-sm font-semibold text-blue-600">
      Total Contacts: <span className="text-gray-800">{shopProgress.totalContacts}</span>
    </p>
    <p className="text-sm font-semibold text-green-600">
      Saved Contacts: <span className="text-gray-800">{shopProgress.savedContacts}</span>
    </p>
    <p className={`text-sm font-semibold ${shopProgress.status === 'completed' ? 'text-green-500' : 'text-yellow-500'}`}>
      Status: <span className="text-gray-800 capitalize">{shopProgress.status}</span>
    </p>
  </div>
)}

        {/* Conditional Rendering for Loading Line and Success Message */}
        {loading && (
          <p className="mt-2 text-blue-500 text-sm">Bulk import in progress...</p>
        )}
        {completed && !loading && (
          <p className="mt-2 text-green-500 text-sm">Bulk import completed!</p>
        )}
      </td>
    </tr>
  );
};

export default DeclinedServiceItem;
